<template>
  <vs-row vs-justify="flex-start">
    <vs-col
      v-for="(ls, index) in filteredList"
      :key="index"
      :vs-lg="colsize(ls.type)"
      vs-xs="12"
      vs-sm="12"
    >
      <BarChart
        v-if="ls.type == 'Lista'"
        :key="ls.code"
        :data="ls.values"
        :title="ls.title"
        :indicator="ls.code"
      />

      <LogoChart
        v-if="ls.type == 'Browse'"
        :key="ls.code"
        :data="ls.values"
        :title="ls.title"
        :indicator="ls.code"
      />

      <DonutChart
        v-if="ls.type == 'Donut'"
        :key="ls.code"
        :title="ls.title.toUpperCase()"
        :data="ls.values"
      />

      <SuperFans
        v-if="ls.type == 'SuperFans'"
        :title="ls.title.toUpperCase()"
        :data="ls.values"
      />
    </vs-col>
  </vs-row>
</template>

<script>
/* Components */
import BarChart from '../../dashboard-components/box-fanbase/BarChart.vue';
import DonutChart from '../../dashboard-components/box-fanbase/DonutChart.vue';
import LogoChart from '../../dashboard-components/box-fanbase/LogoChart.vue';
import SuperFans from '../../dashboard-components/box-fanbase/SuperFans';

/* Services */
import Survey from '@/services/survey';

const surveyService = new Survey();

export default {
  name: '',
  components: {
    BarChart,
    DonutChart,
    LogoChart,
    SuperFans,
  },
  props: {
    boxName: {
      type: String,
      default: '',
    },
    useLoading: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    title: '',
    boxStat: {},
    prj: 0,
    reload: false,
    listas: []
  }),
  computed: {
    ownerId() {
      return this.$store.state.ownerID;
    },
    filteredList(){
      if (!this.listas || !this.listas.length) {
        return [];
      } else {
        return this.listas.filter((el) => el.type != 'Hidden');
      }
    },
    token() {
      const token = this.$store.state.accessToken.split('&');
      const res = token[0].replace('tk=', '');
      return res;
    },
  },
  watch: {
    boxName() {
      this.configureBox();
    },
  },
  created() {
    this.configureBox();
  },
  methods: {
    colsize(chartType) {
      return chartType === 'Donut' ? 8 : 4;
    },
    configureBox() {
      this.$store.commit('SET_boxTitle', 'Aguarde...');

      this.prj = this.$store.state.selectedProject;
      this.name = this.boxName;

      if (this.useLoading) {
        this.$store.dispatch('set_isLoadingActive', true);
      }

      surveyService
        .getBox(this.boxName, this.ownerId, this.token)
        .then(
          (ret) => {
            this.boxStat = ret;
            this.listas = ret.listas;
            this.title = ret.boxTitle;

            this.$store.commit('SET_boxTitle', this.title);

            if (this.useLoading) {
              this.$store.dispatch('set_isLoadingActive', false);
            }

            this.reload = true;
          },
          (err) => {
            alert(err);
          }
        );
    },
  },
};
</script>
